
import styles from './arrangementer.module.css';
import vinterleirPicture from '../vinterleir/Vinterleir.webp';
import sommerskole from '../barneparti/pictures/barnepartiaction.webp'
 
import { Link } from 'react-router-dom';

function Arrangementer() {
    return (
        <div className={`${styles.arrangementerGrid} slideLeft`}>
            <div className={`${styles.textSide}`}>
                <h1>Arrangementer i Ski Taekwondo Klubb</h1>

                <h2>Sommmerskole 2024</h2>
                <div className={`${styles.smallInfo}`}>
                    <label>Dager: 14. august, 15. august, 16. august.</label>
                    <label>Klokken: 10.00-14.00</label>
                    <label>Pris: Gratis</label>
                </div>

                <div className={styles.picture}>
                    <img src={sommerskole} alt="Fellesbilde vinterleir" />
                </div>
                <div>
                    <p>14, 15. og 16. august arrangerer Ski Taekwondo Klubb gratis sommerskole for alle barn på 3-7. trinn.</p>
                    <p>Dette er en fin introduksjon til taekwondo og selvforsvar!</p>
                </div>
                <div>
                <p>Treningene er fra kl. 10:00-14:00 i vår dojang , i Industriveien 17, Ski.</p>
                <p>Det koster ingenting å være med, og man kan være med alle dager eller enkelte dager.</p>
                </div>
                <div>
                <p>Ta med dobok, om du har det, ellers så kommer du i vanlig treningtøy.</p>
                <p>Husk matpakke og drikkeflaske!</p>
                </div>
                <p>Det vil være et tak på 30 deltagere, og et minimum på 5 deltakere per dag.</p>
                <p>Vel møtt!</p>
                <div className={`${styles.subButtons}`}>
                <Link to="/sommerskole/registrering"><button className=''>Påmelding</button></Link>
                </div>

                <hr/>

                <h2>Vinterleir i Ski Taekwondo Klubb</h2>
                <a href={vinterleirPicture} target="_blank" >
                    <img src={vinterleirPicture} alt="Fellesbilde vinterleir" className={styles.textPicture} />
                </a>
                <p>Takk for oppmøtet i 2023, vi gleder oss til vinterleieren 2024.</p>
            </div>
        </div>
    )
}

export default Arrangementer;